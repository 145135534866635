<!-- Mto de Productos -->

<template>
  <div class="productos">

      <!-- Contenido -->
      <div class="contenido">
        <v-sheet :elevation="4">       
          <base_Fedit
            :schema="schema"
            :Entorno="Entorno"
            @onEvent="event_capture">        
          </base_Fedit>
        </v-sheet>
      </div>

  </div>
</template>



<script>

  import { mixinMto_inRow } from "@/mixins/mixinMto_inRow.js";
  import plugs from "@/common/general_plugs";
  const base_Fedit = () => plugs.groute("base_Fedit.vue", "base");

  export default {
    mixins: [mixinMto_inRow],
    components: { base_Fedit },
    props: {
      Entorno_F: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: null,
        Entorno: null,
        headers: {
          header: [
            { text: "NOMBRE", value: "name" }, 
            { text: "CLAVE", value: "clave" },            
            { text: "ACCIONES", value: "acciones", sortable: false, width: "auto" }
          ]
        },
        /* estados:[
            { id:'0', name:'Soltero/a' },
            { id:'1', name:'Casado/a' },
            { id:'2', name:'Viudo/a' },
            { id:'3', name:'No comment' }
        ] */
      };
    },

    methods: {

      // gestor de eventos
      event_capture(evt) 
      {
        console.log("*** onEvent productos.vue. Accion: ", evt, " ***");
        this.acciones_Mto_inRow(evt);
      },

      // entorno particular de base_Fedit
      get_entorno_Mto_inRow() 
      {
        this.Entorno = JSON.parse(JSON.stringify(this.$cfg.base.F));

        this.Entorno.api = "productos_M";
        this.Entorno.header.header = true;
        this.Entorno.header.titulo = "Productos";
        this.Entorno.filtro.filtro = false;
        //this.Entorno.grid.bind["items-per-page"] = -1;
        this.Entorno.grid.bind["hide-default-header"] = false;        
        this.Entorno.grid.headers = (this.headers.header).map(header1 => ({ ...this.Entorno_F.grid.headers, ...header1 }));
      },

      // obtengo los registros a mostrar en el Grid
      get_records() 
      {
          this.Entorno.records.records = this.$store.getters.get_dato_inicial("productos").sort((a, b) => {
            return a.name < b.name ? -1 : 0;
          });
      }

    }
  };
</script>
